
import { getComponent, getConfigEnv, sleep } from '@/utils/helpers';

import { Component, Vue } from 'vue-property-decorator';
import accountModule from '@/store/modules/accountModule';
import jwtDecode from 'jwt-decode';
import workflowModule from '@/store/modules/workflowModule';
import axios from 'axios';
import {
  refreshAccessToken,
} from '@/lib/auth';


@Component({
  components: {
    AuthLayout: () => getComponent('auth/AuthLayout'),
    TasqLoader: () => getComponent('loaders/TasqLoader'),
  },
})
export default class Login extends Vue {
  CODE_LENGTH = 6;

  dataLoading = false;

  socialLoginInProcess = false;

  email = '';

  actionShown = 'login';

  lastExecutionTime: any = 0;

  code = '';

  errorMessage = '';

  ssoErrorMessage = '';

  loading = false;

  get verifyBtnDisabled() {
    return this.code == '';
  }

  get allowSSO() {
    const url = window.location.href;
    return getConfigEnv('ALLOW_SSO') && !url.includes('demo-');
  }

  get cognitoClientID() {
    return getConfigEnv('COGNITO_CLIENT_ID');
  }

  get cognitoDomainURL() {
    return getConfigEnv('COGNITO_HOSTED_DOMAIN');
  }

  get congnitoRedirectURL() {
    return getConfigEnv('COGNITO_GOOGLE_REDIRECT_URL');
  }

  async created() {
    this.lastExecutionTime = Date.now() - 2000
    await this.handleSSOAuthentication();
  }

  /**
   * Handles the Single Sign-On (SSO) login process.
   * @returns {Promise<void>}
   */
  async handleSSOAuthentication() {
    try {
      this.socialLoginInProcess = true;
      await sleep(100);
      const { query }: any = this.$route;

      if (query) {
        await this.handleSSOErrorDescription(query);
      }

      if (query.code) {
        await this.handleCognitoToken(query.code);
      }
    } catch (error) {
      this.ssoErrorMessage =
        'Please try again as the provided code is invalid.';
    }

    await sleep(1000);
    this.socialLoginInProcess = false;
  }

  async handleSSOErrorDescription(query: string) {
    const { error_description }: any = query ?? {};

    if (error_description) {
      if (error_description.includes('userLinked') || error_description.includes('userAdded')) {
        if( error_description.includes('userAdded')){
           await sleep(5000);
        }
        const provider = error_description.includes('SAML')
          ? 'AzureAD'
          : 'Google';
        await this.loginWithIdentityProvider(provider);
      }else if (error_description.includes('UserNotFound')) {
        this.ssoErrorMessage = 'This email is not recognized by Tasq.';
      }else{
        this.ssoErrorMessage = 'We apologize for the inconvenience. SSO encountered an error. Please try logging in through email.'
      }
    }
  }

  async handleCognitoToken(code: string) {
    const cognitoTokenUrl = `https://${this.cognitoDomainURL}.auth.us-east-1.amazoncognito.com/oauth2/token`;

    // @ts-ignore
    const data = new URLSearchParams({
      grant_type: 'authorization_code',
      client_id: this.cognitoClientID,
      redirect_uri: this.congnitoRedirectURL,
      code,
    });

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const {
      data: { id_token, access_token, refresh_token },
    } = await axios.post(cognitoTokenUrl, data, config);

    localStorage.removeItem('IdToken');
    localStorage.setItem('AccessToken', id_token);
    localStorage.setItem('IdToken', access_token);
    localStorage.setItem('RefreshToken', refresh_token);

    const userDetails: any = jwtDecode(id_token);
    accountModule.setDidPreloadData(false);

    if (userDetails?.email) {
      refreshAccessToken()
      await this.handleUserProfile(userDetails);

    }
  }


  async handleUserProfile(userDetails: any) {
    await workflowModule.getUserProfile({
      username: userDetails.email.toLowerCase(),
      useExisting: true,
    });


    if (userDetails['custom:is_sso_verified']?.includes('false')) {
      this.$router.push({ name: 'VerifyInfo' });
    } else {
      this.$router.push({ name: 'Tasqs' });
    }
  }

  /**
   * Logs in the user with the specified identity provider.
   * @param {string} identityProvider - The identity provider to use for the login (e.g. "Google", "AzureAD").
   * @returns {Promise<void>}
   */
  async loginWithIdentityProvider(identityProvider: string) {
    this.socialLoginInProcess = true;
    await sleep(100);
    this.ssoErrorMessage = '';
    this.dataLoading = false;
    const url = `https://${this.cognitoDomainURL}.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=${identityProvider}&redirect_uri=${this.congnitoRedirectURL}&response_type=CODE&client_id=${this.cognitoClientID}&scope=email openid profile`;
    window.location.href = url;
    await sleep(1000);
    this.socialLoginInProcess = false;
  }

  debouncedLogin() {
      const now = Date.now();
      if (now - this.lastExecutionTime >= 6000) { // 10000 milliseconds = 10 seconds
        this.lastExecutionTime = now;
        this.login();
      }
  };

  async login() {
    if(this.dataLoading || !this.email.length){
      return;
    }
    this.errorMessage = '';
    this.dataLoading = true;
    this.loading = true;
    try {
      this.code = ''
      await accountModule.login({ email: this.email });
      this.actionShown = 'verify';
      this.loading = false;
      this.dataLoading = false;
    } catch (e) {
      if (e.message === 'User does not exist.') {
        e.message = 'Please check your email.';
      }
      this.dataLoading = false;
      //   this.errorMessage = e.message;
      this.errorMessage = 'This email is not recognized by Tasq.';
      this.loading = false;
    }
  }

  async verify() {
    if(this.dataLoading || !this.code.length){
      return;
    }
    this.loading = true;
    this.dataLoading = true;
    this.errorMessage = '';
    try {
      await accountModule.verify({ code: this.code });
      accountModule.setDidPreloadData(false);
      // await this.setUserTimezone()
      this.$router.push({ name: 'Home' });

      await sleep(2000);
      this.loading = false;
      this.dataLoading = false;
    } catch (e) {
      e.message =
        'Let’s try that again — check your code and make sure you enter the correct numbers.';
      this.errorMessage = e.message;
      this.loading = false;
      this.dataLoading = false;
    }
  }
}
